<template>
    <a-layout>
        <a-layout-sider :style="pageLayoutSiderStyle">
            <div class="sider-inner" :style="{width:showSide?'100%':'10px'}">
                <div class="switch" @click="switchSider">
                    <a-icon type="backward" v-if="showSide" />
                    <a-icon type="forward" v-else />
                </div>
                
                <div class="list" v-if="showSide">
                    <a-button type="primary" size="small" style="margin-left:10px;" @click.stop="addFloder()"><a-icon type="folder-add" />新增目录</a-button>
                    <a-tree
                        v-if="treeData.length>0"
                        :defaultExpandAll="true"
                        :autoExpandParent="true"
                        :defaultExpandParent="true"
                        :selected-keys="[cubeId]"
                        :tree-data="treeData"
                        @select="onSelect"
                    >
                        <template slot="title" slot-scope="item">
                            <div class="tree-name-wrap">
                                <span class="cube-name">{{item.cubeNameZh}}</span>
                                <a-icon type="folder-add" v-if="item.folderType===0" title="新增目录" @click.stop="addFloder(item)" />
                                <a-icon type="file-add" title="新增数据库" @click.stop="addFile(item)" />
                                <a-icon type="edit" :title="item.folderType===0?'编辑目录':'编辑数据库'" @click.stop="editCube(item)" />
                                <a-icon type="delete" v-if="item.cubeId!==0" :title="item.pid===0?'删除目录':'删除数据库'" @click.stop="deleteCube(item)" />
                            </div>
                        </template>
                    </a-tree>
                </div>
            </div>
        </a-layout-sider>
        <a-layout-content>
            <div class="module-name">
                <div class="name">{{cubeName}}</div>
            </div>
            <div class="inputs">
                <a-row>
                    <a-col :span="15">
                        <div class="input-wrap">
                            <div class="label">创建人员：</div>
                            <a-input style="width:100px;background:#fff" :disabled="true" :value="createUserName" size="small" />
                        </div>
                        <div class="input-wrap">
                            <div class="label">数据量：</div>
                            <a-input v-model="totalNum" :disabled="true" style="width:100px;background:#fff" size="small" />
                        </div>
                        <div class="input-wrap">
                            <div class="label">最新修改：</div>
                            <a-input v-model="lstmodDate" :disabled="true" style="width:100px;background:#fff" size="small" />
                        </div>
                        <div class="clear"></div>
                    </a-col>
                    <a-col :span="9">
                        
                    </a-col>
                </a-row>
            </div>

            <div class="table-area">
                <div class="table-header">
                    <div class="ctrls">
                        <a-input-search placeholder="请输入关键词" style="width: 200px" v-model="keyword" class="search" @search="onSearch" />
                        <div class="ctrl-btn">
                            <div class="item" @click="addDimensionData()">
                                <span class="iconfont icon-tianjia"></span>
                                <div class="name">新增</div>
                            </div>
                            <div class="item" @click="multiEditDimensionData">
                                <span class="iconfont icon-bianji1"></span>
                                <div class="name">编辑</div>
                            </div>
                            <div class="item" @click="multiDeleteDimData">
                                <span class="iconfont icon-jianshao"></span>
                                <div class="name">删除</div>
                            </div>
                            <div class="item" @click="importVisbile=true">
                                <span class="iconfont icon-daoru"></span>
                                <div class="name">导入</div>
                            </div>
                            <div class="item" @click="exportDimData">
                                <span class="iconfont icon-daochu"></span>
                                <div class="name">导出</div>
                            </div>
                        </div>
                    </div>
                    <div class="dimensions">
                        <div class="item" v-for="item in dimensions" :key="item.dimensionId" :class="{'active':selectedDimension.dimensionId===item.dimensionId}" @click="setCurrentDimensionData(item)" v-show="item.dimensionId!=='TIME'">
                            <span class="iconfont" :class="getIconName(item.dimensionId)"></span>
                            <div class="name">{{item.showName}}</div>
                        </div>
                    </div>
                    <div class="clear"></div>
                </div>

                <div class="table" ref="table">
                    <a-table
                    v-if="searchStatus===false"
                    :data-source="currentDimensionData" 
                    @expand="expandDim" 
                    :pagination="false" 
                    class="components-table-demo-nested" 
                    bordered 
                    :row-key="record=>record.dimCode"
                    :row-selection="rowSelection"
                    :key="tableKey"
                    >
                        <a-table-column key="dimCode" title="dimCode" data-index="dimCode" />
                        <a-table-column key="dimName" title="dimName" data-index="dimName" />
                        <a-table-column key="IS_VAL" title="IS_VAL" data-index="IS_VAL" />
                        <a-table-column key="ACTION" title="ACTION" data-index="ACTION">
                            <template slot-scope="text,record">
                                <a-button type="link" @click="addDimensionData(record.dimCode)">添加</a-button>
                                <a-button type="link" @click="updateDimensionData(record)">编辑</a-button>
                                <a-button type="link" @click="deleteDimensionData(record)">删除</a-button>
                            </template>
                        </a-table-column>
                    </a-table>

                    <a-table  
                    v-else
                    :data-source="currentDimensionDataSearch" 
                    :pagination="false" 
                    class="components-table-demo-nested" 
                    bordered 
                    :row-key="record=>record.dimCode"
                    :row-selection="rowSelection"
                    :expandAllRows="true"
                    :key="tableKey"
                    >
                        <a-table-column key="dimCode" title="dimCode" data-index="dimCode" />
                        <a-table-column key="dimName" title="dimName" data-index="dimName" />
                        <a-table-column key="IS_VAL" title="IS_VAL" data-index="IS_VAL" />
                        <a-table-column key="ACTION" title="ACTION" data-index="ACTION">
                            <template slot-scope="text,record">
                                <a-button type="link" @click="addDimensionData(record.dimCode)">添加</a-button>
                                <a-button type="link" @click="updateDimensionData(record)">编辑</a-button>
                                <a-button type="link" @click="deleteDimensionData(record)">删除</a-button>
                            </template>
                        </a-table-column>
                    </a-table>
                </div>
            </div>

            <a-modal 
                :title="waitingEditDimData?'编辑'+selectedDimension.dimensionName:'新增'+selectedDimension.dimensionName"
                :visible="editIndicatorVisible"
                centered
                :footer="null"
                :width="800"
                wrapClassName="edit-indicator-win"
                @cancel="cancelEditIndicator">
                <div class="inputs">
                    <a-row :gutter="[16,16]">
                        <a-col :span="12" v-for="(item,key) in columns" :key="key" v-show="item.filedName">
                            <a-row type="flex">
                                <a-col flex="150px" class="label" :title="item.filedName">{{item.filedName}}</a-col>
                                <a-col flex="auto">
                                    <a-input v-if="item.type==='text'" v-model="item.value" :disabled="waitingEditDimData&&item.filedCode==='DIM_CODE'" />
                                    <a-date-picker v-if="item.type==='date'" valueFormat="YYYY-MM-DD" v-model="item.value" />
                                </a-col>
                            </a-row>
                        </a-col>      

                        <a-col :span="24">
                            <a-row type="flex">
                                <a-col flex="150px" class="label">维度标签</a-col>
                                <a-col flex="auto">
                                    <a-tree-select
                                        v-model="selectedTagIds"
                                        style="width: 100%"
                                        :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                                        tree-checkable
                                        :treeCheckStrictly="true"
                                        :key="dimTreeKey"
                                        :tree-data="tags[selectedDimension.dimensionId]"
                                        :placeholder="'请选择'+selectedDimension.dimensionName+'标签'"
                                    />
                                </a-col>
                            </a-row>
                        </a-col>
                    </a-row>
                    
                </div>
                <div class="btns">
                    <a-button @click="cancelEditIndicator">取消</a-button>
                    <a-button type="primary" @click="confirmAddDimensionData">确定</a-button>
                </div>
            </a-modal>

            <a-modal 
                :title="folderParams.cubeId?'编辑目录':'新增目录'"
                :visible="editDirVisible"
                centered
                :footer="null"
                :width="800"
                wrapClassName="edit-dir-win"
                @cancel="cancelEditDir">
                <div class="inputs">
                    <a-row :gutter="[16,16]">
                        <a-col :span="12">
                            <a-row type="flex">
                                <a-col flex="110px" class="label">目录名称：</a-col>
                                <a-col flex="auto"><a-input v-model="folderParams.name" /></a-col>
                            </a-row>
                        </a-col>
                        <a-col :span="12">
                            <a-row type="flex">
                                <a-col flex="110px" class="label">父级目录：</a-col>
                                <a-col flex="auto">
                                    <!-- <a-input  :disabled="true" :value="folderPNode?folderPNode.cubeNameZh:null" /> -->
                                    <a-tree-select
                                        v-model="folderParams.cubeNameZh"
                                        :treeData="folders"
                                        show-search
                                        style="width: 100%"
                                        :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                                        placeholder="请选择父级目录"
                                        allow-clear
                                        tree-default-expand-all
                                        :treeDataSimpleMode="{id:'cubeId',pId:'pid'}"
                                        :replaceFields="{key:'cubeId',title:'cubeNameZh',value:'cubeId'}"
                                    ></a-tree-select>
                                </a-col>
                            </a-row>
                        </a-col>
                    </a-row>
                    <a-row :gutter="[16,16]">
                        <a-col :span="12">
                            <a-row type="flex">
                                <a-col flex="110px" class="label">排序：</a-col>
                                <a-col flex="auto"><a-input-number v-model="folderParams.ord" /></a-col>
                            </a-row>
                        </a-col>
                    </a-row>
                    <a-row :gutter="[16,16]">
                        <a-col :span="24">
                            <a-row type="flex">
                                <a-col flex="110px" class="label left">目录介绍：</a-col>
                                <a-col flex="auto">
                                    <quill-editor :options="editorOption" :content="folderParams.desc" @change="onEditorChangeFolder" style="height:250px" />
                                </a-col>
                            </a-row>
                        </a-col>
                    </a-row>
                </div>
                <div class="blank" style="height:50px"></div>
                <div class="btns">
                    <a-button @click="cancelEditDir">取消</a-button>
                    <a-button type="primary" @click="confirmAddFolder">确定</a-button>
                </div>
            </a-modal>

            <a-modal 
                :title="editCubeParams.cubeId?'编辑数据库':'新增数据库'"
                :visible="editCubeVisible"
                centered
                :footer="null"
                :width="970"
                wrapClassName="edit-cube-win"
                @cancel="cancelEditCube">

                <div class="inputs">
                    <a-row :gutter="[16,16]">
                        <a-col :span="8">
                            <a-row type="flex">
                                <a-col flex="100px" class="label">数据库名：</a-col>
                                <a-col flex="auto"><a-input v-model="editCubeParams.cubeNameZh" /></a-col>
                            </a-row>
                        </a-col>
                        <a-col :span="8">
                            <a-row type="flex">
                                <a-col flex="100px" class="label">排序：</a-col>
                                <a-col flex="auto"><a-input v-model="editCubeParams.ord" /></a-col>
                            </a-row>
                        </a-col>
                        <a-col :span="8">
                            <a-row type="flex">
                                <a-col flex="100px" class="label">父级数据库：</a-col>
                                <a-col flex="auto">
                                    <a-tree-select
                                        v-model="editCubeParams.parentName"
                                        :treeData="folders"
                                        show-search
                                        style="width: 100%"
                                        :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                                        placeholder="Please select"
                                        allow-clear
                                        tree-default-expand-all
                                        :treeDataSimpleMode="{id:'cubeId',pId:'pid'}"
                                        :replaceFields="{key:'cubeId',title:'cubeNameZh',value:'cubeId'}"
                                    ></a-tree-select>
                                </a-col>
                            </a-row>
                        </a-col>
                    </a-row>

                    <a-row :gutter="[16,16]">
                        <a-col :span="8">
                            <a-row type="flex">
                                <a-col flex="100px" class="label">开始时间：</a-col>
                                <a-col flex="auto">
                                    <a-date-picker valueFormat="YYYY-MM-DD" v-model="editCubeParams.startDate" />
                                </a-col>
                            </a-row>
                        </a-col>
                        <a-col :span="8">
                            <a-row type="flex">
                                <a-col flex="100px" class="label">结束时间：</a-col>
                                <a-col flex="auto">
                                    <a-date-picker valueFormat="YYYY-MM-DD" v-model="editCubeParams.lastDate" />
                                </a-col>
                            </a-row>
                        </a-col>
                        <a-col :span="8">
                            <a-row type="flex">
                                <a-col flex="100px" class="label">数据频度：</a-col>
                                <a-col flex="auto">
                                    <a-select style="width:180px" v-model="editCubeParams.freqId">
                                        <a-select-option :value="1">年度</a-select-option>
                                        <a-select-option :value="3">季度</a-select-option>
                                        <a-select-option :value="4">月度</a-select-option>
                                    </a-select>
                                </a-col>
                            </a-row>
                        </a-col>
                        <a-col :span="24">
                            <a-row type="flex">
                                <a-col flex="100px" class="label">数据来源：</a-col>
                                <a-col flex="auto">
                                    <a-select style="width:100%" mode="multiple" v-model="editCubeParams.sourceIds">
                                        <div slot="dropdownRender" slot-scope="menu">
                                            <v-nodes :vnodes="menu" />
                                            <a-divider style="margin: 4px 0;" v-show="allSourcePage>currentSourcePage" />
                                            <div
                                                style="padding: 4px 8px; cursor: pointer;"
                                                @mousedown="e => e.preventDefault()"
                                                @click="getMoreSource()"
                                                v-show="allSourcePage>currentSourcePage"
                                            >
                                                <a-icon type="plus" /> 更多来源
                                            </div>
                                        </div>
                                        <a-select-option v-for="item in sourceList" :key="item.sourceId" :value="item.sourceId">{{item.sourceName}}</a-select-option>                                        
                                    </a-select>
                                </a-col>
                            </a-row>
                        </a-col>

                        <a-col :span="8">
                            <a-row type="flex">
                                <a-col flex="100px" class="label">数据表名：</a-col>
                                <a-col flex="auto">
                                    <a-input v-model="editCubeParams.viewName" />
                                </a-col>
                            </a-row>
                        </a-col>
                        <a-col :span="8">
                            <a-row type="flex">
                                <a-col flex="100px" class="label">命名空间：</a-col>
                                <a-col flex="auto">
                                    <a-select v-model="editCubeParams.schema" style="width:100%;" placeholder="请选择命名空间">
                                        <a-select-option v-for="item in nameSpaces" :key="item.epsSchema" :value="item.epsSchema">{{item.epsSchemaName}}</a-select-option>
                                    </a-select>
                                </a-col>
                            </a-row>
                        </a-col>
                        <a-col :span="8">
                            <a-row type="flex">
                                <a-col flex="100px" class="label">数据库ID</a-col>
                                <a-col flex="auto">
                                    <a-input v-model="editCubeId" />
                                </a-col>
                            </a-row>
                        </a-col>
                    </a-row>

                    <a-row :gutter="[16,16]">
                        <a-col :span="24">
                            <a-row type="flex">
                                <a-col flex="100px" class="label">数据库介绍：</a-col>
                                <a-col flex="auto">
                                    <quill-editor :options="editorOption" :content="editCubeParams.descZh" @change="onEditorChange" style="height:150px" />
                                </a-col>
                            </a-row>
                        </a-col>
                    </a-row>                    

                    <div style="height:60px;"></div>

                    <a-row :gutter="[16,16]">
                        <a-col :span="24">
                            <a-row type="flex">
                                <a-col flex="100px" class="label">选择维度：</a-col>
                                <a-col flex="auto">
                                    <a-table size="small" :data-source="editCubeParams.dims" :pagination="false"  bordered :row-key="record=>record.dimensionId">
                                        <a-table-column key="dimName" title="维度信息" data-index="dimName" :width="120" />
                                        <a-table-column key="dimensionName" title="维度名称" data-index="dimensionName" :width="120">
                                            <template slot-scope="text,record">
                                                <a-input v-model="record.name" />
                                            </template>
                                        </a-table-column>
                                        <a-table-column key="dimensionDesc" title="维度介绍" data-index="dimensionDesc" />
                                        <a-table-column key="pos" title="位置" data-index="pos" :width="50" align="center">
                                            <template slot-scope="text,record">
                                                <span v-if="record.pos===1">
                                                    列
                                                </span>
                                                <span v-else>
                                                    行
                                                </span>
                                            </template>
                                        </a-table-column>
                                        <a-table-column key="action" title="操作" data-index="action" align="center" :width="150">
                                            <template slot-scope="text,record">
                                                <a-button type="link" v-if="record.pos===1" @click="record.pos=0">设为行</a-button>
                                                <a-button type="link" v-else @click="record.pos=1">设为列</a-button>
                                                <a-button type="link" @click="removeDim(record.dimensionId)">删除</a-button>
                                            </template>
                                        </a-table-column>
                                    </a-table>
                                    <a-button type="link" size="large" @click="addDimensions();"><a-icon type="plus-circle" />增加维度</a-button>
                                </a-col>
                            </a-row>
                        </a-col>
                    </a-row>
                </div>

                <div class="btns">
                    <a-button @click="cancelEditCube">取消</a-button>
                    <a-button type="primary" @click="confirmEditCube">确定</a-button>
                </div>
            </a-modal>

            <a-modal 
                title="增加维度"
                :visible="addDimensionVisbile"
                centered
                :footer="null"
                :width="900"
                wrapClassName="edit-cube-win"
                @cancel="cancelAddDimension">
                <div class="table">
                    <a-table size="small"  :row-selection="rowSelection2" :data-source="allDimensions" :pagination="false"  bordered :row-key="record=>record.dimensionId">
                        <a-table-column key="dimensionName" title="维度信息" data-index="dimensionName" :width="120" />
                        <a-table-column key="dimensionDesc" title="维度介绍" data-index="dimensionDesc" />
                    </a-table>
                </div>
                <div class="btns">
                    <a-button @click="cancelAddDimension">取消</a-button>
                    <a-button type="primary" @click="confirmAddDimension">确定</a-button>
                </div>
            </a-modal>

            <a-modal 
                :title="'导入'+selectedDimension.dimensionName"
                :visible="importVisbile"
                centered
                :footer="null"
                @cancel="importVisbile=false"
                :destroyOnClose="true"
                :width="600">
                <div class="btns btns2" style="padding:10px 0;text-align:left;margin:0;">
                    <a-button type="primary" ghost size="small" @click="getMould">
                        获取{{selectedDimension.dimensionName}}维度模板
                    </a-button>
                </div>
                <a-upload-dragger
                    name="file"
                    :multiple="false"
                    :action="uploadUrl"
                    accept=".xls,.xlsx"
                    @change="handleChangeUpload"
                    :data="{dimType:selectedDimension.dimensionId,cubeId:cubeId}"
                >
                    <p class="ant-upload-drag-icon">
                        <a-icon type="inbox" />
                    </p>
                    <p class="ant-upload-text">
                        点击或将文件拖拽到这里上传
                    </p>
                    <p class="ant-upload-hint">
                        支持扩展名为.xls、.xlsx的文件，文件大小最大为100MB
                    </p>
                </a-upload-dragger>
            </a-modal>
        </a-layout-content>
    </a-layout>
</template>

<script>
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import { quillEditor } from 'vue-quill-editor';
import {pageLayoutSiderStyle,pageLayoutSiderHideStyle} from '../../until/global_style';
import config from '../../until/configs';
import { getCookie, messageTips,objToUrl } from '../../until/tools';
import { TreeSelect } from 'ant-design-vue';
const SHOW_PARENT = TreeSelect.SHOW_PARENT;
export default {
    name:'Cube',
    components:{quillEditor,VNodes: {
      functional: true,
      render: (h, ctx) => ctx.props.vnodes,
    },},
    data() {
        return {
            pageLayoutSiderStyle:pageLayoutSiderStyle,
            showSide:true,
            treeData:[],
            proCubes:[],
            dimensions:[],
            allDimensions:[],
            sheetId:'',
            totalNum:null,
            lstmodDate:null,
            createUserName:'',
            selectedDimension:{},
            dimensionDatas:[],
            currentDimensionData:[],
            currentDimensionDataSearch:[],
            searchStatus:false,
            keyword:'',
            selectedDimsDataKey:[],
            rowSelection:{
                onChange: (selectedRowKeys) => {
                    this.selectedDimsDataKey = selectedRowKeys;
                },
                onSelect: (record, selected, selectedRows) => {
                    this.selectedDimsDataKey = selectedRows.map(item=>item.dimCode);
                },
                onSelectAll: (selected, selectedRows) => {
                    this.selectedDimsDataKey = selectedRows.map(item=>item.dimCode)
                },
            },
            editIndicatorVisible:false,
            waitingEditIndicator:null,

            editDirVisible:false,
            importVisbile:false,
            editCubeVisible:false,
            waitingEditCube:null,
            addDimensionVisbile:false,
            rowSelection2:{
                onChange: (selectedRowKeys) => {
                    this.rowSelection2.selectedRowKeys = selectedRowKeys;
                },
                onSelect: (record, selected, selectedRows) => {
                    this.selectedDimsKey = selectedRows.map(item=>item.dimensionId);
                },
                onSelectAll: (selected, selectedRows) => {
                    this.selectedDimsKey = selectedRows.map(item=>item.dimensionId)
                },
                selectedRowKeys:[]
            },
            selectedDimsKey:[],
            uploadUrl:config.inputs_upload_dim_data+'?sid='+getCookie('bd_sid'),
            editorOption: {
                placeholder: "请在这里输入",
                height:200,
                theme:'snow',
                modules:{
                    toolbar:[
                        ['bold', 'italic'],    //加粗，斜体，下划线，删除线
                        ['blockquote'],     //引用，代码块
                        [{ 'header': 1 }, { 'header': 2 }],        // 标题，键值对的形式；1、2表示字体大小
                        [{ 'list': 'ordered'}, { 'list': 'bullet' }],     //列表
                        [{ 'indent': '-1'}, { 'indent': '+1' }],     // 缩进
                        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],     //几级标题
                        [{ 'align': [] }],    //对齐方式
                        //['image']    //上传图片、上传视频
                    ]
                }
            },
            waitingEditDimData:null,
            columns:[],
            waitingAddChildrenNode:null,
            
            folders:[],
            editCubeId:null,
            editCubeParams:{
                cubeNameZh:'',
                pid:null,
                sourceIds:[],
                freqId:1,
                startDate:null,
                lastDate:null,
                dims:[],
                ord:1,
                descZh:'',
                viewName:'',
                schema:''
            },
            folderParams:{
                name:'',
                ord:undefined,
                desc:'',
                pid:null
            },
            folderPNode:null,
            tableKey:0,
            sourceList:[],
            nameSpaces:[],
            tags:{},
            selectedTagIds:[],
            dimTreeKey:0,
            SHOW_PARENT,
            currentSourcePage:1,
            allSourcePage:1
        }
    },
    computed:{
        cubeId() {
            let id = this.$route.query.cubeId;
            if(id) {
                return parseInt(id);
            }
            return null;
        },
        userInfo() {
            return this.$store.getters.getUserInfo;
        },
        cubeName() {
            let name = this.$route.query.cubeName;
            if(name) {
                return name;
            }
            return null;
        }
    },
    watch:{
        cubeId(value) {
            if(value) {
                this.selectedDimension = {};
                this.getDimensionData();
                this.selectedKeys = [value];
                this.getCubeInfo(value);
            }
        },
    },
    created() {
        this.getCubes().then((res)=>{            
            if(this.cubeId) {
                this.getDimensionData();
                this.getCubeInfo(this.cubeId);
            } else {
                if(res[0]&&res[0].children) {
                    let cubeId,cubeName;
                    res[0].children.some(item=>{
                        if(item.children&&item.children.length>0) {
                            cubeId = item.children[0].cubeId;
                            cubeName = item.children[0].cubeNameZh;
                            return true;
                        }
                    })
                    this.$router.push({path:'/inputs_cube',query:{cubeId,cubeName}});
                }
            }
        });

        this.$http.getNameSpaces().then(res=>{
            if(res.list) {
                this.nameSpaces = res.list;
            }
        });

        this.getFolders();
              
    },
    methods:{
        getCubes() {
            return this.$http.inputs_get_cubes().then(res=>{
                if(res) {
                    if(res.list) {
                        let treeData = this.getTreeList(res.list,0,[]);
                        this.treeData = treeData;
                    }
                    this.proCubes = res.list;
                    
                    return this.treeData;
                }
                return false;
            })
        },
        switchSider() {
            this.showSide = !this.showSide;
            if(this.showSide) {
                this.pageLayoutSiderStyle = pageLayoutSiderStyle;
            } else {
                this.pageLayoutSiderStyle = pageLayoutSiderHideStyle
            }
        },        

        onSelect(selectedKeys, info) {
            this.tableKey = Math.random();
            if(info.node.dataRef.folderType===1) {
                this.$router.push({path:'/inputs_cube',query:{cubeId:info.node.eventKey,cubeName:info.node.dataRef.cubeNameZh}});
            }            
        },

        onSearch(e) {
            //messageTips('维度数据是层级展示，异步加载，不支持搜索',2);
            if(!e) {
                this.searchStatus = false;
                return false;
            }
            this.$http.searhDimension({dimensionId:this.selectedDimension.dimensionId,keyword:e,cubeId:this.cubeId}).then(res=>{
                if(res&&res.dimensionList) {
                    this.currentDimensionDataSearch = this.setDimTree(res.dimensionList,'-1');
                    this.searchStatus = true;
                }
            })
        },

        getFolders() {
            this.$http.inputs_get_cubes({folderType:0}).then(res=>{
                if(res) {
                    if(res.list) {
                        this.folders = res.list;
                    }
                }
            })
        },

        addFile(item) {
            this.addCube(item);
        },

        addFloder(pNode) {
            if(pNode) {
                this.folderPNode = pNode;
                this.folderParams.pid = pNode.cubeId;
                this.folderParams.cubeNameZh = pNode.cubeNameZh
            } else {
                this.folderPNode = null;
                this.folderParams.pid = null;
                this.folderParams.cubeNameZh = null
            }
            this.editDirVisible=true;
        },

        cancelEditIndicator() {
            this.editIndicatorVisible = false;
            this.waitingEditIndicator = null;
            this.waitingEditDimData = null;
            this.selectedTagIds = [];
        },
        cancelEditDir() {
            this.editDirVisible = false;
            this.folderPNode = null;
            this.folderParams = {
                ord:'',
                name:'',
                desc:'',
                pid:null
            }
        },
        cancelEditCube() {
            this.editCubeVisible = false;
        },
        cancelAddDimension() {
            this.addDimensionVisbile = false;
        },

        getAllDimension() {
            return this.$http.input_get_dimensions().then(res=>{
                if(res&&res.list) {
                    this.allDimensions = res.list;
                }
                return res;
            })
        },


        getDimensionData() {
            this.$http.cloud_get_dimension({cubeId:this.cubeId,isInput:2}).then(res=>{
                if(res&&res.dataletBO&&res.dataletBO.allDimensions&&res.dataletBO.allDimensions.dimensionVOList) {
                    this.sheetId = res.dataletBO.sheetId; 
                    this.dimensions = res.dataletBO.allDimensions.dimensionVOList;
                    res.dataletBO.allDimensions.dimensionVOList.forEach(item=>{
                        this.tags[item.dimensionId] = [];
                    });
                    if(!this.selectedDimension.dimensionId) {
                        this.dimensions.some((item=>{
                            if(item.fieldCode!=='TIME_CODE') {
                                this.selectedDimension = item;
                                return true;
                            }
                        }));
                    }                                 
                    res.dataletBO.allDimensions.dimensionVOList.forEach(item=>{
                        item.objList.forEach(i=>{
                            if(i.isParent&&!i.children) {
                                i.children = [];
                            }
                        })
                    })
                    this.dimensionDatas = res.dataletBO.allDimensions.dimensionVOList;
                    this.setCurrentDimensionData();
                }
            });
        },
        setCurrentDimensionData(item) {
            if(item) {
                this.selectedDimension = item;
            }
            this.searchStatus = false;
            this.keyword = '';
            this.currentDimensionData = [];
            this.dimensionDatas.some(item=>{
                if(item.dimensionId===this.selectedDimension.dimensionId) {
                    this.currentDimensionData = [...item.objList];
                    return true;
                }
            });

            this.getTags();
        },
        

        getTreeList(arr,pid,result) {
            arr.forEach((item)=>{
                item.value = item.cubeId;
                item.key = item.cubeId;
                item.scopedSlots = { title: 'title' };
                item.title = item.cubeNameZh;
                item.titleCopy = item.cubeNameZh;
                if(item.pid===pid) {
                    if(!item.children) {
                        item.children = [];
                    }
                    item.children = this.getTreeList(arr,item.cubeId,item.children);
                    result.push(item);
                }
            });
            return result;
        },
        getIconName(name) {
            let icoName='';
            switch (name) {
                case 'INDICATOR':
                    icoName = 'icon-tongbizengchangshuai';
                    break;
                case 'CLASSIFY':
                    icoName = 'icon-fenlei';
                    break;
                case 'REGION':
                    icoName = 'icon-diqu';
                    break;
                case 'TIME':
                    icoName = 'icon-31shijian';
                    break;
                case 'INDUSTRY':
                    icoName = 'icon-hangye';
                    break;
                case 'COUNTRY':
                    icoName = 'icon-guojia';
                    break;                    
                default:
                    icoName = 'icon-m-moren';
                    break;
            }
            return icoName;
        },
        expandDim(e,node) {
            let params = {
                cubeId:this.cubeId,
                parentCode:node.dimCode,
                dimType:this.selectedDimension.dimensionId,
                sheetId:this.sheetId
            }
            if(node.children.length>0) {
                return false;
            }
            this.$http.getDimensionChildren(params).then(res=>{
                if(res.dimensionList) {
                    res.dimensionList.forEach(item=>{
                        if(item.isParent) {
                            item.children = [];
                        }
                    })
                    node.children = [...res.dimensionList];
                    this.currentDimensionData = [...this.currentDimensionData];
                }
            });
        },
        handleChangeUpload({file}) {
            if(file.status==='done') {
                if(!file.response.success) {
                    messageTips(file.response.message,1);
                    return false;
                }
                messageTips('导入成功',3);
                this.importVisbile = false;
                this.getDimensionData();                
            }
        },
        getMould() {
            let params = {
                dimType:this.selectedDimension.dimensionId,
                sid:getCookie('bd_sid')
            }
            let targetUrl = config.baseUrl[0]+'upload/getModelExcel?'+objToUrl(params);
            window.location.href = targetUrl;
        },
        getTagTree(arr,pid) {
            let result = [];
            arr.forEach(item=>{
                if(item.parentId===pid) {
                    let children = this.getTagTree(arr,item.tagId);
                    if(children.length>0) {
                        item.children = children;
                    }
                    result.push(item);
                }
            });
            return result;
        },
        getTags() {
            this.$http.getTags({dimensionId:this.selectedDimension.dimensionId}).then(res=>{
                if(res&&res.list) {
                    res.list.forEach(item=>{
                        item.title = item.tagName;
                        item.key = item.tagId;
                        item.value = item.tagId;
                    })
                    let listTree = this.getTagTree(res.list,'-1');
                    this.tags[this.selectedDimension.dimensionId] = listTree;
                    this.dimTreeKey = Math.random();
                }
            })
        },
        addDimensionData(pid) {
            if(pid) {
                this.waitingAddChildrenNode = pid;
            }

            this.editIndicatorVisible = true;
            if(this.tags[this.selectedDimension.dimensionId].length===0) {
                this.getTags();
            }
            this.columns = [];
            this.$http.inputs_get_dimension_columns({dimType:this.selectedDimension.dimensionId}).then(res=>{
                if(res&&res.columnsMapList) {
                    res.columnsMapList.forEach(item=>{
                        let obj = {...item};
                        if(item.isTime) {
                            obj.type = 'date';
                        } else {
                            obj.type = 'text';
                        }
                        if(item.filedCode==='PARENT_ID') {
                            if(pid) {
                                obj.value=pid;
                            } else {
                                obj.value = '-1';
                            }
                        } else {
                            obj.value='';
                        }
                        this.columns.push(obj);
                    });
                }                
            })
        },
        confirmAddDimensionData() {
            let params = {
                lang:1,
                cubeId:this.cubeId,
                dimType:this.selectedDimension.dimensionId,
                tagIds:this.selectedTagIds.map(item=>item.value).join(',')
            };
            let tips = ''
            this.columns.some(item=>{
                if(item.notNull==='true'&&item.value==='') {
                    tips = item.filedName+'不能为空';
                    return true;
                }
            });
            if(tips!=='') {
                messageTips(tips,2);
                return false;
            }
            this.columns.forEach(item=>{
                params[item.filedCode] = item.value;
            });
            let funcName = 'inputs_add_dim_data';
            if(this.waitingEditDimData) {
                funcName = 'inputs_edit_dim_data';
            }
            this.$http[funcName](params).then(res=>{
                if(res) {
                    if(res.success) {
                        messageTips(res.message,3);
                        this.columns = [];
                        this.waitingEditDimData = null;
                        this.editIndicatorVisible = false;
                        if(funcName==='inputs_add_dim_data') {
                            if(params.PARENT_ID!=='-1') {
                                let param = {
                                    cubeId:this.cubeId,
                                    parentCode:params.PARENT_ID,
                                    dimType:this.selectedDimension.dimensionId,
                                    sheetId:this.sheetId
                                }
                                this.$http.getDimensionChildren(param).then(res=>{
                                    if(res.dimensionList) {
                                        res.dimensionList.forEach(item=>{
                                            if(item.isParent) {
                                                item.children = [];
                                            }
                                        });
                                        let arr = this.addChildren(this.currentDimensionData,params.PARENT_ID,res.dimensionList);
                                        this.currentDimensionData = [...arr];
                                    }
                                });
                            } else {
                                this.getDimensionData();
                            }
                        } else {
                            let arr = this.updateDimensionDataName(this.currentDimensionData,params.DIM_CODE,params.DIM_NAME);
                            this.currentDimensionData = [...arr];
                        }
                    } else {
                        messageTips(res.message,1);
                    }
                }
            })
        },
        addChildren(data,pid,arr) {
            data.some(item=>{
                if(item.dimCode===pid) {
                    item.children = arr;
                    return true;
                }
                if(item.children) {
                    this.addChildren(item.children,pid,arr);
                }                
            });
            return data;
        },
        updateDimensionData(record) {
            this.waitingEditDimData = record;
            this.editIndicatorVisible = true;
            if(this.tags[this.selectedDimension.dimensionId].length===0) {
                this.getTags()
            }
            this.columns = [];
            this.$http.inputs_get_dim_data({cubeId:this.cubeId,dimType:this.selectedDimension.dimensionId,dimCode:record.dimCode}).then(res=>{
                if(res&&res.columnsMapList) {
                    res.columnsMapList.forEach(item=>{
                        let obj = {...item};
                        if(item.isTime) {
                            obj.type = 'date';
                        } else {
                            obj.type = 'text';
                        }
                        obj.value = obj.filedVal==='0000-00-00'?'':obj.filedVal;
                        
                        if(item.tagList) {
                            this.selectedTagIds = item.tagList.map(i=>{return {value:i.tagId,label:i.tagName}});
                        } else {
                            this.columns.push(obj);
                        }
                    });
                    
                }
            })
        },
        deleteDimensionData(record) {
            let that = this;
            this.$confirm({
                title: '提示',
                content: '您确定要删除吗？',
                okText: '确定',
                cancelText: '取消',
                centered:true,
                onOk() {
                    that.$http.inputs_delete_dim_data({cubeId:that.cubeId,dimType:that.selectedDimension.dimensionId,dimCodes:record.dimCode}).then(res=>{
                        if(res) {
                            if(res.success) {
                                messageTips(res.message,3);
                                that.removeCurrentDimData(that.currentDimensionData,record.dimCode);
                            }else {
                                messageTips(res.message,1);
                            }
                        }
                    });
                }
            });
        },
        multiDeleteDimData() {
            if(this.selectedDimsDataKey.length===0) {
                messageTips('请选择要删除的数据',2);
                return false;
            }
            let that = this;
            this.$confirm({
                title: '提示',
                content: '您确定要删除吗？',
                okText: '确定',
                cancelText: '取消',
                centered:true,
                onOk() {
                    that.$http.inputs_delete_dim_data({cubeId:that.cubeId,dimType:that.selectedDimension.dimensionId,dimCodes:that.selectedDimsDataKey.join(',')}).then(res=>{
                        if(res) {
                            if(res.success) {
                                messageTips(res.message,3);
                                that.getDimensionData();
                                that.selectedDimsDataKey = [];
                            }else {
                                messageTips(res.message,1);
                            }
                        }
                    });
                }
            });
        },
        multiEditDimensionData() {
            if(this.selectedDimsDataKey.length===0) {
                messageTips('请选择要编辑的数据',2);
                return false;
            }
            if(this.selectedDimsDataKey.length>1) {
                messageTips('只能编辑一条数据',2);
                return false;
            }
            this.waitingEditIndicator = this.selectedDimsDataKey[0];
            this.updateDimensionData({dimCode:this.selectedDimsDataKey[0]});
        },
        addCube(item) {
            this.initCubeParams();
            this.editCubeVisible = true;
            this.editCubeParams.pid = item.cubeId;
            this.editCubeParams.parentName = item.cubeNameZh
            this.getFolders();
            if(this.sourceList.length===0) {
                this.getSources();
            }
        },
        editCube(item) {
            if(item.folderType===0) {
                this.$http.inputs_get_cube_info({cubeId:item.cubeId}).then(res=>{
                    if(res&&res.MetaCube) {
                        this.folderParams = {
                            cubeId:res.MetaCube.cubeId,
                            name:res.MetaCube.cubeNameZh,
                            ord:res.MetaCube.ord,
                            desc:res.MetaCube.descZh,
                            pid:res.MetaCube.pid
                        }
                        this.editDirVisible = true;
                    }
                })
            } else {
                this.editCubeVisible = true;
                this.getCubeInfo(item.cubeId);
                if(this.sourceList.length===0) {
                    this.getSources();
                }
            }
        },
        getSources(page=1) {
            this.$http.getSource({currentPage:page}).then(res=>{
                if(res.list) {
                    this.sourceList = [...this.sourceList,...res.list];
                    this.currentSourcePage = page;
                    this.allSourcePage = res.page.allPage;
                }
            })
        },
        getMoreSource() {
            this.getSources(this.currentSourcePage+1);
        },
        getCubeInfo(cubeId) {
            this.getFolders();
            return this.$http.inputs_get_cube_info({cubeId:cubeId}).then(res=>{
                if(res) {
                    if(res.MetaCube) {
                        this.editCubeParams.cubeId = res.MetaCube.cubeId;
                        this.editCubeId = res.MetaCube.cubeId;
                        this.editCubeParams.cubeNameZh = res.MetaCube.cubeNameZh;
                        this.editCubeParams.ord = res.MetaCube.ord;
                        this.editCubeParams.pid = res.MetaCube.pid;
                        this.editCubeParams.descZh = res.MetaCube.descZh;                        
                        this.editCubeParams.freqId = res.MetaCube.freqId;
                        this.editCubeParams.startDate = res.MetaCube.startDate?.substring(0,10);
                        this.editCubeParams.lastDate = res.MetaCube.lastDate?.substring(0,10);                        
                        this.editCubeParams.viewName = res.MetaCube.viewName;                        
                        this.editCubeParams.schema = res.MetaCube.schema;
                        this.editCubeParams.sourceIds = res.dicSourceList.map(item=>item.sourceId);
                        if(res.rsCubeDimList) {
                            let dims = [];
                            res.rsCubeDimList.forEach(item=>{
                                let obj = {
                                    dimName:item.dimName,
                                    dimensionDesc:item.dimensionDesc,
                                    name:item.dimensionName,
                                    pos:item.position,
                                    dimensionId:item.dimensionId
                                }
                                dims.push(obj);
                            });
                            this.editCubeParams.dims = dims;
                        }

                        this.totalNum = res.MetaCube.totalNum;
                        this.lstmodDate = res.MetaCube.lstmodDate;
                        this.createUserName = res.MetaCube.createUserName;
                    }
                    
                }
                return false;
            });
        },
        deleteCube(item) {
            let that = this;
            let tips = '';
            if(item.pid===10000) {
                if(item.children.length>0) {
                    messageTips('请先删除子库',2);
                    return false;
                }
                tips = '您确定要删除当前目录吗？';
            } else {
                tips = '您确定要删除当前库吗？';
            }
            this.$confirm({
                    title: '提示',
                    content: tips,
                    okText: '确定',
                    cancelText: '取消',
                    centered:true,
                    onOk() {
                        that.$http.inputs_delete_cube({cubeId:item.cubeId}).then(res=>{
                            if(res) {
                                if(res.success) {
                                    messageTips(res.message,3);
                                    that.getCubes();
                                    if(item.cubeId===that.cubeId) {
                                        let cubeId,cubeName;
                                        that.proCubes.some(item=>{
                                            if(item.folderType===1) {
                                                cubeId = item.cubeId;
                                                cubeName = item.cubeNameZh;
                                            }
                                        });
                                        that.$router.push({path:'/inputs_cube',query:{cubeId,cubeName}});
                                    }
                                }else {
                                    messageTips(res.message,1)
                                }
                            }
                        })
                    }
                });
            
        },
        addDimensions() {
            let arr = this.editCubeParams.dims.map(item=>{
                return item.dimensionId;
            });
            this.getAllDimension().then(()=>{
                this.addDimensionVisbile = true;
                this.rowSelection2.onChange(arr);
            });
            
            
        },
        confirmAddDimension() {
            this.addDimensionVisbile = false;
            let arr = [];
            this.allDimensions.forEach(item=>{
                if(this.selectedDimsKey.indexOf(item.dimensionId)!==-1) {
                    let obj = {...item};
                    obj.name = item.dimensionName;
                    obj.pos = 0;
                    arr.push(obj);
                }
            });
            this.editCubeParams.dims = arr;
        },
        confirmEditCube() {
            if(this.editCubeParams.cubeNameZh==='') {
                messageTips('请填写数据库名',2);
                return false;
            }
            if(this.editCubeParams.pid===null) {
                messageTips('请选择父级库',2);
                return false;
            }
            if(this.editCubeParams.startDate===null) {
                messageTips('请选择开始时间',2);
                return false;
            }
            if(this.editCubeParams.lastDate===null) {
                messageTips('请选择结束时间',2);
                return false;
            }
            if(this.editCubeParams.lastDate===null) {
                messageTips('请选择结束时间',2);
                return false;
            }
            if(this.editCubeParams.dims.length===0) {
                messageTips('请添加维度',2);
                return false;
            }
            if(this.editCubeParams.dims.length<2) {
                messageTips('请至少添加2个维度',2);
                return false;
            }
            let rows = 0,cols = 0;
            this.editCubeParams.dims.forEach(item=>{
                if(item.pos===0) {
                    cols+=1;
                } else {
                    rows+=1;
                }
            });
            if(rows===0||cols===0) {
                messageTips('请确保行或列至少1个维度',2);
                return false;
            }
            let dims = [];
            this.editCubeParams.dims.forEach(item=>{
                let obj = {
                    dimId:item.dimensionId,
                    dimName:item.name,
                    pos:item.pos
                }
                dims.push(obj);
            });
            let params = {
                cubeNameZh:this.editCubeParams.cubeNameZh,
                pid:this.editCubeParams.pid,
                sourceIds:this.editCubeParams.sourceIds.join(','),
                freqId:this.editCubeParams.freqId,
                ord:this.editCubeParams.ord,
                startDate:this.editCubeParams.startDate,
                lastDate:this.editCubeParams.lastDate,
                descZh:this.editCubeParams.descZh,
                dims,
                viewName:this.editCubeParams.viewName,
                schema:this.editCubeParams.schema
            }
            let funcName='inputs_add_cube';
            if(this.editCubeParams.cubeId) {
                params.cubeId = this.editCubeParams.cubeId;
                funcName = 'inputs_edit_cube';
            } else {
                if(this.editCubeId) {
                    params.cubeId = this.editCubeId;
                }
            }
            this.$http[funcName](params).then(res=>{
                if(res) {
                    if(res.success) {
                        messageTips(res.message,3);
                        this.getCubes();
                        this.initCubeParams();
                        this.editCubeVisible = false;
                        this.getDimensionData();
                    } else {
                        messageTips(res.message,1);
                    }
                }
            });
        },
        removeDim(id) {
            let index;
            this.editCubeParams.dims.some((item,key)=>{
                if(item.dimensionId===id) {
                    index = key;
                    return true;
                }
            });
            this.editCubeParams.dims.splice(index,1);
        },
        onEditorChange(e) {
            this.editCubeParams.descZh = e.html;
        },
        onEditorChangeFolder(e) {
            this.folderParams.desc = e.html;
        },
        initCubeParams() {
            this.editCubeId = null;
            this.editCubeParams = {
                cubeNameZh:'',
                pid:null,
                sourceIds:[],
                freqId:1,
                startDate:null,
                lastDate:null,
                dims:[],
                ord:1,
                descZh:'',                
                viewName:'',
                schema:''
            }
        },
        removeCurrentDimData(arr,code) {
            arr.some((item,key)=>{
                if(item.dimCode===code) {
                    arr.splice(key,1);
                    this.tableKey = Math.random();
                    return true;
                }
                if(item.children) {
                    this.removeCurrentDimData(item.children,code);
                }                
            });
        },

        updateDimensionDataName(arr,code,name) {
            arr.some((item)=>{
                if(item.dimCode===code) {
                    item.dimName = name;
                    return true;
                }
                this.updateDimensionDataName(item.children,code,name);
            });
            return arr;
        },
        confirmAddFolder() {
            if(this.folderParams.name==='') {
                messageTips('请输入目录名称',2);
                return false;
            }
            if(this.folderParams.ord===undefined) {
                messageTips('请填写排序',2);
                return false;
            }
            let params = {
                cubeNameZh:this.folderParams.name,
                ord:this.folderParams.ord,
                descZh:this.folderParams.desc,
                pid:this.folderParams.pid?this.folderParams.pid:0
            }
            let functionName = '';
            if(this.folderParams.cubeId) {
                params.cubeId = this.folderParams.cubeId;
                functionName = 'inputs_edit_cube_folder';
            } else {
                functionName = 'inputs_create_cube_folder';
            }
            this.$http[functionName](params).then(res=>{
                if(res) {
                    if(res.success) {
                        messageTips(res.message,3);
                        this.getCubes();
                        this.cancelEditDir();
                    } else {
                        messageTips(res.message,1);
                    }
                }
            })
        },
        setDimTree(list,pid) {
            let arr = [];
            list.forEach(item=>{
                if(item.parentId===pid) {
                    let children = this.setDimTree(list,item.dimCode);
                    if(children.length>0) {
                        item.children = children;
                    }
                    arr.push(item);
                }
            });
            return arr;
        },
        exportDimData() {
            let params = {
                cubeId:this.cubeId,
                lang:1,
                dimType:this.selectedDimension.dimensionId,
                sid:getCookie('bd_sid')
            }
            let url = objToUrl(params)
            window.location.href = config.inputs_export_dim+'?'+url
        }
    }
}
</script>

<style lang="scss" scoped>
    @import '../../scss/cube.scss';
</style>
<style lang="scss" scoped>
.tree-name-wrap {
    .anticon {
        margin-left:5px;
        display: none;
    }
    .cube-name {
        display: block;
        float:left;
    }
}
.tree-name-wrap:hover {
    .anticon {
        display: inline;
    }
    .cube-name {
        max-width:120px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

</style>